/*
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-07-28 11:31:46
 */
import { Toast } from 'vant'
import axios from 'axios'
import { useStore } from 'vuex'
import store from '@/store/index'
// import VueAxios from 'vue-axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: 'http://192.168.48.72:8099/',
  withCredentials: true,
  timeout: 10 * 60 * 1000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.warn('请求异常:' + error)
  return Promise.reject(error)
}

function objToFormData(obj) {
  const form = new FormData()
  Object.keys(obj).forEach((item) => {
    form.set(item, obj[item])
  })
  return form
}

// request interceptor
request.interceptors.request.use((config) => {
  const hgOrgUserId = localStorage.getItem('hg-org-userId') || ''
  const types = ['POST']
  const { params = {}, method } = config
  // console.log(hgOrgUserId, method.toLowerCase())
  // hgOrgUserId && console.log(hgOrgUserId)
  if (types.includes(method.toLowerCase())) {
    config.data = objToFormData(params)
    delete config.params
  }

  config.headers['authorization'] = hgOrgUserId ? JSON.parse(hgOrgUserId).sessionId : ''
  // config.headers['authorization'] = 'd2537497-5b97-43ac-8d2a-4c0733784260'
  return config
}, errorHandler)

// response interceptor
const codes = [4001, 4002, 4003, 2008, 2001, 5001, 3004, 3007, 3009, 3006, 3033, 3005]
request.interceptors.response.use((response) => {
  
  const hgOrgUserId = localStorage.getItem('hg-org-userId') || ''
  const { code, message } = response.data
  if (+code === 601 || +code === 613) {
    // 登录超时 携带参数退出 跳转到登录页
    const { pathname, search } = location
    if (hgOrgUserId) {
      ;+code !== 0 &&
        Toast({
          message: message || '服务器未知错误',
          className: 'global-toast'
        })
    }
    localStorage.removeItem('hg-org-userId')
    localStorage.removeItem('currentOrder')
    localStorage.removeItem('cartData')
    localStorage.removeItem('index')
    localStorage.removeItem('MINE_INFO')
    window.location.href = `/login?redirect=${pathname}${search}`
  } else if (codes.includes(+code)) {
    //
  } else {
    ;+code !== 0 &&
      Toast({
        message: message || '服务器未知错误',
        className: 'global-toast'
      })
  }
  return response.data
}, errorHandler)
export default request // 局部使用

export { request as axios } // 全局使用
