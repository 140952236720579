import { createRouter, createWebHistory } from 'vue-router'

const viewComponents = {
  blankLayout: {
    type: 0,
    component: () => import(/* webpackChunkName: "blankLayout" */ '@/layout/BlankLayout.vue')
  },
  // 账户余额
  accountBalance: {
    type: 1,
    component: () => import(/* webpackChunkName: "accountBalance" */ '@/views/accountBalance/index.vue')
  },
  // 登录
  login: {
    type: 1,
    component: () => import(/* webpackChunkName: "login" */ '@/views/myUser/Login.vue')
  },
  // 注册
  register: {
    type: 1,
    component: () => import(/* webpackChunkName: "register" */ '@/views/myUser/register.vue')
  },
  // 忘记密码
  forgetPassword: {
    type: 1,
    component: () => import(/* webpackChunkName: "password" */ '@/views/myUser/forgetPassword.vue')
  },
  my: {
    type: 1,
    component: () => import(/* webpackChunkName: "my" */ '@/views/my')
  },
  // 提现与充值
  balanceCard: {
    type: 2,
    component: () => import(/* webpackChunkName: "balanceCard" */ '@/views/accountBalance/balanceCard.vue')
  },
  // 首页
  home: {
    type: 1,
    component: () => import(/* webpackChunkName: "home" */ '@/views/home')
  },
  // 套餐详情
  shopDetail: {
    type: 2,
    component: () => import(/* webpackChunkName: "shopDetail" */ '@/views/shopDetail')
  },
  // 购物车
  shopCar: {
    type: 1,
    component: () => import(/* webpackChunkName: "shopCar" */ '@/views/shopCar')
  },
  // 我的团队
  myTeam: {
    type: 2,
    component: () => import(/* webpackChunkName: "myTeam" */ '@/views/myTeam')
  },
  // 元宝
  yuanBao: {
    type: 2,
    component: () => import(/* webpackChunkName: "yuanBao" */ '@/views/yuanBao')
  },
  // 账户管理
  accountManagement: {
    type: 2,
    component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement')
  },
  // 个人信息
  personalInformation: {
    type: 2,
    component: () =>
      import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement/personalInformation.vue')
  },
  // 修改昵称
  editNickName: {
    type: 2,
    component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement/editNickName.vue')
  },
  // 设置支付密码
  editPayPassword: {
    type: 2,
    component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/accountManagement/editPayPassword.vue')
  },
  // 地址管理
  addressManagement: {
    type: 2,
    component: () => import(/* webpackChunkName: "addressManagement" */ '@/views/addressManagement')
  },
  // 确认订单
  payOrder: {
    type: 2,
    component: () => import(/* webpackChunkName: "payOrder" */ '@/views/shopCar/payOrder.vue')
  },
  // 地址列表
  addr: {
    type: 3,
    component: () => import(/* webpackChunkName: "addr" */ '@/views/addressManagement/addr.vue')
  },
  // 修改地址
  editAddr: {
    type: 3,
    component: () => import(/* webpackChunkName: "edit" */ '@/views/addressManagement/add.vue')
  },
  // 添加地址
  addAddr: {
    type: 3,
    component: () => import(/* webpackChunkName: "addr" */ '@/views/addressManagement/add.vue')
  },

  // 我的业绩
  myPerformance: {
    type: 2,
    component: () => import(/* webpackChunkName: "payOrder" */ '@/views/myPerformance')
  },
  // 代理商协议
  agreement: {
    type: 3,
    component: () => import(/* webpackChunkName: "agreement" */ '@/views/shopCar/agreement.vue')
  },
  // 支付成功
  paySuccess: {
    type: 3,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/paySuccess')
  },
  // 余额转账
  transfer: {
    type: 3,
    component: () => import(/* webpackChunkName: "transfer" */ '@/views/accountBalance/transfer.vue')
  },
  // 支付成功
  inviteFriends: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/inviteFriends')
  },
  // 团队箱数
  teamOrder: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/teamOrder')
  },
  // 兑换余额
  exchange: {
    type: 3,
    component: () => import(/* webpackChunkName: "exchange" */ '@/views/yuanBao/exchange.vue')
  },
  // 我的金豆
  myGoldenBean: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/myGoldenBean')
  },
  // 我的订单
  myOrder: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/myOrder')
  },
  // 订单详情
  orderDetail: {
    type: 2,
    component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/orderDetail')
  },
  // 充值成功
  rechargeSuccess: {
    type: 2,
    component: () => import(/* webpackChunkName: "rechargeSuccess" */ '@/views/rechargeSuccess')
  },
  // 商城
  shopping: {
    type: 1,
    component: () => import(/* webpackChunkName: "rechargeSuccess" */ '@/views/shopping')
  },
  // 资讯文章
  informationalArticles: {
    type: 2,
    component: () => import(/* webpackChunkName: "rechargeSuccess" */ '@/views/informationalArticles')
  },
  // 身份切换
  identitySwitching: {
    type: 2,
    component: () => import(/* webpackChunkName: "identitySwitching" */ '@/views/myUser/identitySwitching')
  },
  // 我的直推
  myDirectPush: {
    type: 2,
    component: () => import(/* webpackChunkName: "myDirectPush" */ '@/views/myDirectPush')
  },
  // 平台分红
  myBonus: {
    type: 2,
    component: () => import(/* webpackChunkName: "myBonus" */ '@/views/myBonus')
  },
}

// 配置路由
const routes = [
  {
    path: '/',
    // name：'basicLayout',
    component: viewComponents.blankLayout.component,
    children: [
      {
        path: '/my',
        component: viewComponents.my.component
      },
      {
        path: 'home',
        component: viewComponents.home.component
      },
      {
        path: 'shopCar',
        component: viewComponents.shopCar.component
      },
      {
        path: 'shopping',
        component: viewComponents.shopping.component
      }
    ]
  },
  {
    path: '/accountBalance',
    component: viewComponents.accountBalance.component
  },
  {
    path: '/login',
    name: 'login',
    component: viewComponents.login.component
  },
  {
    path: '/register/:code?',
    name: 'register',
    component: viewComponents.register.component
  },
  // {
  //   path: '/forgetPassword',
  //   name: 'forgetPassword',
  //   component: viewComponents.forgetPassword.component
  // },
  {
    path: '/balanceCard',
    component: viewComponents.balanceCard.component
  },
  {
    path: '/shopDetail/:id',
    component: viewComponents.shopDetail.component
  },
  {
    path: '/yb',
    component: viewComponents.yuanBao.component
  },
  {
    path: '/accountManagement',
    component: viewComponents.accountManagement.component
  },
  {
    path: '/addressManagement',
    component: viewComponents.addressManagement.component,
    children: [
      {
        path: 'list',
        component: viewComponents.addr.component
      },
      {
        path: 'edit/:id',
        component: viewComponents.editAddr.component
      },
      {
        path: 'addAddr',
        component: viewComponents.addAddr.component
      }
    ]
  },
  {
    name: 'myOrder',
    path: '/myOrder',
    meta: {
      cacheList: ['orderDetail'],
      keepAlive: true // 设置页面是否需要使用缓存
    },
    component: viewComponents.myOrder.component
  },
  {
    path: '/orderDetail/:id',
    name: 'orderDetail',
    component: viewComponents.orderDetail.component
  },
  {
    path: '/myTeam',
    component: viewComponents.myTeam.component
  },
  {
    path: '/inviteFriends',
    component: viewComponents.inviteFriends.component
  },
  {
    path: '/payOrder',
    component: viewComponents.payOrder.component
  },
  {
    path: '/myPerformance',
    component: viewComponents.myPerformance.component
  },
  {
    path: '/teamOrder',
    component: viewComponents.teamOrder.component
  },
  {
    path: '/agreement',
    component: viewComponents.agreement.component
  },
  {
    path: '/paySuccess/:id',
    component: viewComponents.paySuccess.component
  },
  {
    path: '/transfer',
    component: viewComponents.transfer.component
  },
  {
    path: '/exchange/:type',
    component: viewComponents.exchange.component
  },
  {
    path: '/myGoldenBean',
    component: viewComponents.myGoldenBean.component
  },
  {
    path: '/personalInformation',
    component: viewComponents.personalInformation.component
  },
  {
    path: '/editNickName',
    component: viewComponents.editNickName.component
  },
  {
    path: '/editPayPassword',
    component: viewComponents.editPayPassword.component
  },
  {
    path: '/rechargeSuccess',
    component: viewComponents.rechargeSuccess.component
  },
  {
    path: '/informationalArticles/:id',
    component: viewComponents.informationalArticles.component
  },
  {
    path: '/identitySwitching/:type',
    component: viewComponents.identitySwitching.component
  },
  {
    path: '/myDirectPush',
    component: viewComponents.myDirectPush.component
  },
  {
    path: '/myBonus',
    component: viewComponents.myBonus.component
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
