import request from '@/utils/request'
const apis = {
  logout: '/uic/logout?json=1',
  userInfo: '/drinks/app/v1/user',
  editPayPassword: '/drinks/app/v1/user/pay/password',
  swiper:'/file/upload/one'
}

export const logout = () => {
  return request({
    method: 'GET',
    url: apis.logout
  })
}

export const getUserInfo = () => {
  return request({
    method: 'GET',
    url: apis.userInfo
  })
}

export const updateUserinfo = (data) => {
  return request({
    method: 'PUT',
    url: apis.userInfo,
    data
  })
}

export const editPayPassword = (data) => {
  return request({
    method: 'POST',
    url: apis.editPayPassword,
    data
  })
}

// 轮播图
export const swiper = (data) => {
  return request({
    method: 'POST',
    url: apis.swiper,
    data
  })
}