const state = () => ({
  informationData: [
    // {
    //   id: 1,
    //   picUrl: require('@/assets/images/home/list_img_02.jpg'),
    //   title: '2022年9月，宁波善财商贸公司筹办的财神谷酒品酒会在当地盛大举行',
    //   content:
    //     '2022年9月6日，财神谷酒宁波地区品酒会在宁波国大雷迪森广场酒店盛大举行。 宁波善财公司一直秉承着“善行天下，财满万家”的核心理念，誓做良心产品，弘扬社会正能量。公司出品的“财神酒”酱香突出，酒体醇厚，回味悠长，空杯留香。',
    //   createTime: '2022-10-14 11:33:40'
    // },
    
    {
      id: 1,
      picUrl: require('@/assets/images/home/tudui.png'),
      title: '财神谷酒代理商第二届团建会隆重举行',
      content:
        '春风春日春浩荡，兔年兔岁兔吉祥。福禄寿喜财神到，五福临门家业旺。2023年1月6日，以“财神谷酒，感恩有你”为主题的财神谷酒代理商第二届团建会在宁波象山半边山度假酒店隆重举行。来自全国各地的优秀代理商齐聚一堂，相互交流，共谋财神谷酒新征程！',
      createTime: '2023-01-19 10:35:50'
    },
    {
      id: 2,
      picUrl: require('@/assets/images/home/list_img_01.jpg'),
      title: '“善行天下，财满万家”善财商贸有限公司举行财神谷酒代理团建活动！',
      content:
        '宁波善财商贸有限公司秉承“善行天下•财满万家”的核心理念，践行团结、友爱、互助的企业文化，组织区代家人在台州临海“羊岩山庄度假酒店”举办财神谷酒代理商第一次团建活动。',
      createTime: '2022-10-14 11:33:40'
    }
  ],
  details: [
    // {
    //   id: 1,
    //   detail: `
    //   <p class="title">2022年9月，宁波善财商贸公司筹办的财神谷酒品酒会在当地盛大举行！</p>
    //   <p class="time">2022-10-14 11:33:40<span>发布</span></p>
    //   <div class="context">2022年9月6日，财神谷酒宁波地区品酒会在宁波国大雷迪森广场酒店盛大举行。宁波善财公司一直秉承着“善行天下，财满万家”的核心理念，誓做良心产品，弘扬社会正能量。公司出品的“财神酒”酱香突出，酒体醇厚，回味悠长，空杯留香。</div>
    //   <div class="imgs">
    //     <img src="${require('@/assets/images/home/information/information_01.jpg')}" alt="" />
    //   </div>
    //   <div class="context">“财神酒”不仅是人与人，也是人与天地，人与神灵交流的工具，它不仅赋予我们更多的才华和激情。更会给我们带来财气、贵气、好运气！“喝财神谷酒，财神牵您手，富贵跟您走”现场嘉宾热情参与、积极交流、以酒会友、洽谈商机。</div>
    //   <div class="imgs">
    //     <img src="${require('@/assets/images/home/information/information_02.jpg')}" alt="" />
    //     <img src="${require('@/assets/images/home/information/information_03.jpg')}" alt="" />
    //   </div>
    //   `
    // },
    {
      id: 1,
      detail: `
      <p class="title">财神谷酒代理商第二届团建会隆重举行</p>
      <p class="time">2023-01-19 10:35:50<span>发布</span></p>
      <div class="context"></div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/tudui.png')}" alt="" />
      </div>
      <div class="context">春风春日春浩荡，兔年兔岁兔吉祥。福禄寿喜财神到，五福临门家业旺。2023年1月6日，以“财神谷酒，感恩有你”为主题的财神谷酒代理商第二届团建会在宁波象山半边山度假酒店隆重举行。来自全国各地的优秀代理商齐聚一堂，相互交流，共谋财神谷酒新征程！</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/jianghua.png')}" alt="" />
      </div>
      <div class="context" style="margin-bottom: 0">宁波世纪繁荣旅游开发有限公司董事长黄水财先生出席会议。</div>
      <div class="context" style="margin-bottom: 0">黄董事长对2022年善财公司和财神谷酒代理商取得的成绩表示充分肯定。宁波善财商贸有限公司作为总公司的全资子公司，黄董事长强调“财神谷”商标品牌由其独家享有，期望“财神谷酒”早日打造上市。</div>
      <div class="context">董事长从品牌价值、名度和用户体验等方面突出阐述财神谷酒的优势，分析2022年全国白酒市场情况，并鼓励大家要坚定信心，将目标锁定全国前100名。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/jianghua2.png')}" alt="" />
      </div>
      <div class="context">宁波世纪繁荣旅游开发有限公司董事、总裁王淑姹女士作重要发言，首先王总裁肯定宁波善财商贸有限公司2022年在总经理张忠成先生和各区代理商共同努力下所获得的优异成绩。在新的一年里，王总裁动员大家共同努力为财神谷和善财公司继续添砖加瓦。祝愿大家生意红红火火，口袋鼓鼓，我们的财神谷酒一定会走进千家万户。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/jianghua3.png')}" alt="" />
      </div>
      <div class="context" style="margin-bottom: 0">宁波善财商贸有限公司总经理张忠成先生发表热情洋溢的讲话，张总表示在董事长和总公司各位领导的大力支持以及财神谷酒代理商的共同努力下，善财商贸公司一路披荆斩棘，奋勇前进。财神谷酒迎来快速发展的新阶段。张总对代理商们表达深深的感谢。</div>
      <div class="context" style="margin-bottom: 0">张总表示“财神谷”商标品牌是无价之宝，其中“招财进宝”，“年年富贵”，“龙行天下”，“五福临门”等财神谷酒系列产品的创意设计，融入了他对财神谷深厚的情感，每款酒与景区景点相得益彰，寓意非凡。</div>
      <div class="context">张总坚定执行“善行天下，财满万家”的核心理念，携手财神谷酒的伙伴们，将“团结、有爱、互助”进行到底，再创辉煌。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/jianghua4.png')}" alt="" />
      </div>
      <div class="context">宁波金澜酒业有限公司市场运营部总监石钰林先生回顾过往，总结经验，为各位地区代理商作激情四溢的精彩分享。石总从财神谷酒的品牌价值、文化内涵、生产工艺等向大家做全方位的解说，详细分析市场前景，并制定2023年销售计划和目标。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/jianghua5.png')}" alt="" />
      </div>
      <div class="context">参会的百余位财神谷酒代理商聚精会神地聆听领导和嘉宾的重要讲话。疫情三年，各行各业受到前所未有的沉重打击，财神谷酒在2022年却能逆流而上，展现出蓬勃发展的良好趋势。多位杰出代理商代表上台展望财神谷酒的未来，立定目标，表示将坚定不移地完成2023年的销售业绩目标。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/gz1.png')}" alt="" />
        <img src="${require('@/assets/images/home/gz2.png')}" alt="" />
        <img src="${require('@/assets/images/home/gz3.png')}" alt="" />
      </div>
      <div class="context">夏礼军先生作为财神谷酒优秀代理商之一，率先突破销售目标，他愿意与代理商们保持密切交流，分享财神谷酒的销售经验。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/gz4.png')}" alt="" />
      </div>
      <div class="context">会议期间，黄董事长为2022年取得优秀成绩的代理商们颁发奖品，并合影留念。大家共进晚餐，觥筹交错，放声高歌，一起为财神谷酒的未来送上最美好的祝福。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/gz5.png')}" alt="" />
      </div>
      `
    },
    {
      id: 2,
      detail: `
      <p class="title">“善行天下，财满万家”善财商贸有限公司举行财神谷酒代理团建活动！</p>
      <p class="time">2022-10-14 11:33:40<span>发布</span></p>
      <div class="context">宁波善财商贸有限公司秉承“善行天下•财满万家”的核心理念，践行团结、友爱、互助的企业文化，组织区代家人在台州临海“羊岩山庄度假酒店”举办财神谷酒代理商第一次团建活动。本次活动意义重大，不仅兑现了承诺，也规划畅想未来的发展。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/information_04.jpg')}" alt="" />
        <img src="${require('@/assets/images/home/information/information_05.jpg')}" alt="" />
        <img src="${require('@/assets/images/home/information/information_06.jpg')}" alt="" />
      </div>
      
      `
    },
    {
      id: 3,
      detail: `
      <div class="imgs">
       <img src="${require('@/assets/images/home/information/alcohol_01.png')}" alt="" />
      </div>
      <div class="context indent">宁波善财商贸有限公司系上金谷文化旅游风景区产业链公司。</div>
      <div class="context indent">公司秉承“善行天下 · 财满万家”的核心理念，致力做好“财神谷”文创衍生产品。誓做良心产品，弘扬社会正能量。愿与社会有缘志士共同将“财神谷”优质产品传送至千家万户。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/alcohol_04.png')}" alt="" />
      </div>
      <div class="context indent">财神谷《年年富贵》白酒来自中国酒都茅台镇，选用当地优质糯高粱、小麦、赤水河水为原料，严格按照传统酱香型白酒的独特工艺精心酿造而成。</div>
      <div class="imgs">
      <img src="${require('@/assets/images/home/information/alcohol_02.png')}" alt="" />
      </div>
       <div class="context indent">财神谷《招财进宝》白酒来自中国酒都茅台镇，选用当地优质糯高粱、小麦、赤水河水为原料，严格按照传统酱香型白酒的独特工艺精心酿造而成。</div>
       <div class="imgs">
          <img src="${require('@/assets/images/home/information/alcohol_03.png')}" alt="" />
      </div>
      <div class="context indent">财神谷《鸿运当头》来自北纬38°-我国最知名的葡萄酒产区宁夏，选用国际名贵酿酒葡萄赤霞珠为原料。采用橡木桶适度陈酿结合现代化进口设备低温发酵精心酿造而成。</div>
      <div class="imgs">
          <img src="${require('@/assets/images/home/information/alcohol_05.png')}" alt="" />
      </div>
      <div class="context indent">财神谷酒《黄精酒》是采用天台山脉野生山黄精，独到的地下酒窖恒温发酵技术，严格遵循古法酿造技艺，以糯米与九蒸九晒野生山黄精侵泡六个月酿造而成​。其酒性温和，酒色清亮，酒味醇厚，具有与众不同的​典雅韵味。</div>
      `
    },
    {
      id: 4,
      detail: `
      <div class="context">“上金谷文化旅游风景区”规划总投资约58亿元人民币，总规划面积8200亩。是以独特的商业文化景观展示为核心(其中建有69米高范零造像作为中华民族五千年的商业代表人物)，自然山水风光体验为特色，集生态保护、会议度假、休闲养生、商业购物为一体的综合性滨河型文化旅游风景区，是宁海县、宁波市、浙江省三级重点工程项目。
      一期项目，即“上金谷”主景区投资约20亿元，已于2016 年正式启动施工，内容为:一号停车场、商业街、游客服务中心、金湾大桥、上金谷大牌楼、商帮文化堂、龙柱广场、中华龙柱、聚宝盆广场、财富广场、范器主题雕塑广场、淘宝街、儿童乐园、水景灯光秀等
      二期项目投资约40亿元，内容为:高星级温泉酒店及国际商业论坛中心、SPA娱乐度假中心高端体检医疗中心、国际顶尖养生中心、高标准商务酒店、企业家会所、高端景观住宅区房车营地，未来宝藏，历代帝王造像游览步道，水上财富之夜、私人飞机俱乐部等。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/scenic_01.png')}" alt="" />
        <img src="${require('@/assets/images/home/information/scenic_02.png')}" alt="" />
      </div>
      `
    },
    {
      id: 5,
      detail: `
      <div class="imgs">
       <img src="${require('@/assets/images/home/information/scenic_03.png')}" alt="" />
      </div>
      <div class="context">“牌楼是中国建筑文化的独特景观，是由中国文化诞生的特色建筑，承载着中国特有的建筑艺术和文化。它最早出现在周朝，最初是被用作旌表节孝的纪念物，后来在园林、寺观、宫苑、陵墓和街道都有建造。</div>
      <div class="context indent">圣境牌楼，高23.58米，宽56.99米，非常雄伟壮丽。牌楼上可以看到聚财、增寿、晋禄、添喜、赐福这五组二字吉言，因此也被叫做“五福临门”。值得一提的是，“上金谷”这三个字是由景区创始人黄水财先生亲笔所书。</div>
      <div class="context indent">在牌楼的两侧，有狮象坐阵。古时候，大象因身材高大，性格温和，善解人意，能帮助人完成强度很大的工作，所以被视为力量的象征。它四蹄粗壮，着地稳如泰山，所以在封建统治者看来，象是代表江山稳固、社会安定的标志。在象的背上放一宝瓶，有些瓶内再插放五谷，寓意太平有象、五谷丰登。又因为“象”与“景象”的“象”字同音；“宝瓶”的“瓶”字与“太平”的“平”字同音；这样“大象宝瓶”就成为“太平有象”的吉祥标志了。</div>
      <div class="context indent">牌楼上有一副对联：上联是“水源长润泽情系兆民臻大爱名利能舍”，下联是“财气永氤氲道开华夏秉初衷悦愉自得”。这幅对联生动地概述了范蠡传奇的一生，只因“名利能舍”，才能“悦愉自得”。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/scenic_04.png')}" alt="" />
        <img src="${require('@/assets/images/home/information/scenic_05.png')}" alt="" />
      </div>
      `
    },
    {
      id: 6,
      detail: `
      <div class="imgs">
       <img src="${require('@/assets/images/home/information/scenic_06.png')}" alt="" />
      </div>
      <div class="context indent">龙柱广场正中央，有一座圆形的水池，中间是一座镌刻中华五千年历史的巨型龙柱，威严高耸地矗立在广场中心，代表着中华民族精神的生生不息，渊源流传。龙柱顶部的地球暗喻着天下一体、宽容海涵、兼爱非攻。底部是一座龙宫，平时一条象征着我中华民族的铜铸巨龙就栖息在那里。景区每天会分4个时间段播放音乐，每当音乐高亢响起时，水池中就会波涛汹涌、巨浪翻腾，象征我中华民族的巨龙渐渐苏醒，傲然出世，遨九州，踏乾坤，龙行天下，纵横四海，360度向人间播撒财富之水。</div>
      <div class="context indent">中华龙柱景点演绎的内容：历经磨难的中华民族在伟大的中国共产党与国家领导人的带领下，走上了崛起与腾飞之路，展现给世人的是绚丽多姿的人文风情与风格迥异的民族文化，不断碰撞、交融、演变后形成的一部巨卷,在浩瀚历史长河中经历过血与火的熔炼,更凝聚着华夏儿女的无穷智慧。龙行天下，纵横四海，德行千里，福泽八方，这是中华龙柱景点的深厚内涵。</div>
      
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/scenic_07.png')}" alt="" />
      </div>
      <div class="context indent">6.91米高的聚宝盆，接天财、 地财，世间所有财气都聚其中，招财进宝，滋生财富，财运永恒，福泽子孙。</div>
      <div class="imgs">
        <img src="${require('@/assets/images/home/information/scenic_08.png')}" alt="" />
      </div>
      `
    }
  ],
  informationDetail: null
})

// getters
const getters = {}

// actions
const actions = {
  getInformationDetail({ commit, state }, paylod) {
    const detail = state.details.find((item) => item.id === paylod)
    commit('setInformationDetail', detail)
  }
}

// mutations
const mutations = {
  setInformationDetail(state, paylod) {
    state.informationDetail = paylod
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
