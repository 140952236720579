import CardList from './CardList'
import Select from './Select'
import Tabs from './Tabs'
import NavBar from './NavBar'
import Search from './Search'
import TabBar from './TabBar'
import ShopModal from './ShopModal'
import PayKeyboard from './PayKeyboard'
import Dialog from './Dialog'
import PayDialog from './payDialog'
import PayType from './PayType'
import perDialog from './PerDialog'
const components = [
  CardList,
  Select,
  TabBar,
  Tabs,
  Search,
  NavBar,
  ShopModal,
  PayKeyboard,
  Dialog,
  PayDialog,
  PayType,
  perDialog
]
const install = (app) => {
  components.forEach((component) => {
    app.component(component.name, component)
  })
}
export default {
  install
}
