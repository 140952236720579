<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: 周达
 * @Date: 2022-09-13 09:11:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-19 17:02:23
-->
<template>
  <my-Dialog
    :title="infoTitle"
    :content="infoContent"
    :cancelText="cancelText"
    :okText="okText"
    @onCancel="onCancel"
    @onHandleOk="onHandleOk"
    :showLeftBtn="showLeftBtn"
  ></my-Dialog>
</template>
<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
  props: {
    infoShow: {
      type: Boolean,
      default: false
    },
    infoCode: {
      type: Number,
      default: 0
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '1'
    },
    routerType: {
      type: String,
      default: '1'
    }
  },
  name: 'payDialog',
  setup(props, { emit }) {
    // const infoShow  =
    const router = useRouter()
    const infoTitle = ref('')
    const infoContent = ref('')
    const cancelText = ref('')
    const okText = ref('')
    const showLeftBtn = ref(true)
    //
    const onHandleOk = () => {
      if (props.infoCode === 4001 || props.infoCode === 9000 || props.infoCode === 8004) {
        emit('update:show', false)
        emit('handleShow')
        return
      }

      if (props.infoCode === 8001) {
        router.push({
          path: '/editPayPassword'
        })
        emit('update:show', false)
      }

      if (props.infoCode === 4002) {
        router.replace({
          path: '/editPayPassword'
        })
        return
      }
      if (props.infoCode === 8002 || props.infoCode === 3005) {
        emit('update:show', false)

        return
      }
      if (props.infoCode === 4003) {
        emit('update:show', false)
        emit('errThree')

        return
      }

      if (props.infoCode === 8003) {
        emit('update:show', false)
        emit('getMessage')
      }

      if (props.infoCode === 8000) {
        router.push({
          path: '/balanceCard',
          query: {
            type: 0,
            source: 1
          }
        })
        return
      }
    }

    //
    const onCancel = () => {
      if (props.infoCode === 4001) {
        return router.replace({
          path: '/editPayPassword'
        })
      }

      if (props.infoCode === 4002) {
        if (props.type === '1') {
          return router.replace({
            path: '/myOrder',
            query: {
              orderStatus: 1
            }
          })
        }
        emit('update:show', false)
      }

      if (props.infoCode === 8000 || props.infoCode === 8001 || props.infoCode === 8002 || props.infoCode === 8004) {
        localStorage.removeItem('orderId')
        emit('update:show', false)
        return
      }
      if (props.infoCode === 8003) {
        document.getElementById('pay')?.remove()
        localStorage.removeItem('orderId')
        props.type === '3' || props.routerType === '2'
          ? emit('update:show', false)
          : router.replace({ path: '/myOrder', query: { orderStatus: 1 } })
        //  订单详情里支付重刷数据
        if (props.routerType === '2') {
          emit('updateOderInfo')
        }

        return
      }
      // 其余
      if (props.type === '1') {
        props.routerType === '2'
          ? emit('update:show', false)
          : router.replace({ path: '/myOrder', query: { orderStatus: 1 } })
        localStorage.removeItem('orderId')
      } else {
        emit('update:show', false)
      }
    }

    watch(
      () => props.infoCode,
      (val) => {
        console.log(val)
        showLeftBtn.value = true
        const type = props.type
        // 密码错误
        if (val === 4001) {
          infoTitle.value =
            type === '1' ? '支付失败' : type === '2' ? '兑换失败' : type === '3' ? '账户提现' : '账户转账'
          cancelText.value = '忘记密码'
          okText.value = '重试'
          infoContent.value = '支付密码错误请重新输入'
          return
        }
        // 输入密码错误到达3次
        if (val === 4003) {
          infoTitle.value =
            type === '1' ? '订单支付' : type === '2' ? '兑换余额' : type === '3' ? '账户提现' : '账户转账'
          infoContent.value = '支付密码错误已达3次，请1分钟后再试'
          okText.value = '知道了'
          showLeftBtn.value = false
          return
        }
        // 输入密码错误达到5次
        if (val === 4002) {
          infoTitle.value =
            type === '1' ? '订单支付' : type === '2' ? '兑换余额' : type === '3' ? '账户提现' : '账户转账'
          cancelText.value = '取消'
          okText.value = '修改支付密码'
          infoContent.value = '支付密码错误已达5次，请重新设置'
          return
        }
        // 支付失败
        if (val === 9000) {
          infoTitle.value = '支付失败'
          infoContent.value = '订单支付失败，请重新支付'
          cancelText.value = '稍后支付'
          okText.value = '重新支付'
        }

        // 未设置支付密码
        if (val === 8001) {
          infoTitle.value = '操作失败'
          cancelText.value = '取消'
          okText.value = '去设置'
          infoContent.value = '未设置支付密码，无法进行支付'
        }

        // 余额不足
        if (val === 8000) {
          infoTitle.value = '账户余额'
          cancelText.value = '其他方式支付'
          okText.value = '立即充值'
          infoContent.value = '账户余额不足以支付此次订单'
        }
        if (val === 8002 || val === 3005) {
          infoTitle.value = '金豆余额'
          cancelText.value = '稍后支付'
          okText.value = '其他方式支付'
          infoContent.value = '账户剩余金豆数量不足以支付此次订单'
        }
        // 微信h5支付
        if (val === 8003) {
          infoTitle.value = type === '1' ? '微信支付' : type === '3' ? '微信充值' : ''
          cancelText.value = '稍后支付'
          okText.value = '支付完成'
          infoContent.value = '您有一笔订单正在支付中，是否已支付完成！'
        }

        // 充值失败
        if (val === 8004) {
          infoTitle.value = '充值失败'
          infoContent.value = '账户充值失败，请重试'
          cancelText.value = '取消'
          okText.value = '重试'
        }
      },
      {
        deep: true
      }
    )

    return {
      infoTitle,
      infoContent,
      cancelText,
      okText,
      showLeftBtn,
      onHandleOk,
      onCancel
    }
  },
  emits: ['handleShow', 'update:show', 'errThree', 'getMessage', 'updateOderInfo']
}
</script>
<style lang="scss"></style>
