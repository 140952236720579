<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: 周达
 * @Date: 2022-09-13 09:11:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-11-02 17:10:44
-->
<template>
  <div class="confrim">
    <van-dialog :show="show" :showConfirmButton="false">
      <div class="title bold">
        <slot name="title">{{ title }}</slot>
      </div>
      <div class="content">
        <slot name="content">{{ content }}</slot>
      </div>
      <div class="btn">
        <slot name="cancel">
          <div v-if="showLeftBtn" class="cancel" @click="onCancel">{{ cancelText }}</div>
        </slot>
        <slot name="ok">
          <div v-if="showRightBtn" class="ok" @click="handleOk">
            {{ okText }}
          </div>
        </slot>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'myDialog',
  props: {
    cancelText: {
      type: String,
      default: '取消'
    },
    okText: {
      type: String,
      default: '确认'
    },
    content: {
      type: String,
      default: '支付密码错误，请重试'
    },
    title: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    showLeftBtn: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const onCancel = () => {
      // emit('update:show', false)
      emit('onCancel')
    }

    const handleOk = () => {
      emit('onHandleOk')
    }

    return {
      onCancel,
      handleOk
    }
  },
  emits: ['update:show', 'onCancel', 'onHandleOk']
}
</script>
<style lang="scss" scoped>
.confrim {
  :deep(.van-popup) {
    padding: 24px 16px;
    width: 300px;
    // height: 166px;
    background: #ffffff;
    border-radius: 4px;

    .title {
      font-size: 17px;
      font-weight: 500;
      color: #343434;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .content {
      font-size: 15px;
      font-weight: 400;
      color: #343434;
      line-height: 22px;
    }
  }
}
.btn {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  div {
    // display: flex;

    padding: 9px 0;
    font-size: 15px;
    font-weight: 400;
    color: #343434;
    line-height: 22px;
    text-align: center;
    width: 128px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #343434;
    background: #fff;
    margin: 0 6px;
  }
  .cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0;
  }
  .ok {
    background: #ba2927;
    color: #ffffff;
    border: none;
  }
}
</style>
