<!--
 * @version: 1.0
 * @Author: 张勇康
 * @Date: 2022-08-03 11:19:10
-->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="cachedViews">
      <component :is="Component" />
    </keep-alive>
    <!-- <component :is="Component" v-if="!$route.meta.keepAlive" /> -->
  </router-view>
  <div class="my">
    <!-- <Dialog :show="portFail" @onCancel="portFail = false" :showLeftBtn="false" okText="确认" @onHandleOk="handlePortOK">
      <template #content>
        <div style="font-size: 18px">
          <p style="font-weight: bold">尊敬的财神谷酒用户：</p>
          <p style="text-indent: 2em">
            由于用户量与日活量的激增，对现有服务器进行扩容升级，在此期间，可能会出现无法进入财神谷酒小程序的情况，给您带来的不便，敬请谅解。预计升级时间<span
              style="font-weight: bold; color: brown"
            >
              本周五（2023年3月17日）8:00-17:00</span
            >
            如您在使用过程中遇到任何问题，请及时联系我们的客服人员，我们将竭诚为您服务。同时，我们也欢迎您对我们的产品提出宝贵的建议和意见，以便我们更好地改进和完善。
            再次感谢您对财神谷酒的支持和信任！ 财神谷酒团队
          </p>
        </div>
      </template>
    </Dialog> -->
  </div>
  <div class="my">
    <Dialog :show="show" @onCancel="show = false" :showLeftBtn="false" okText="确认更新" @onHandleOk="handleOK">
      <template #content>
        <p style="display: flex; justify-content: center; align-items: center; font-size: 21px">
          版本已更新,请点击确认更新
        </p>
      </template>
    </Dialog>
  </div>
</template>
<script>
// import VConsole from 'vconsole'
// const vConsole = new VConsole({ maxLogNumber: 1000 })
import userApi from '@/api/user'
// import NavBar from '@/components/NavBar'
import { useRouter } from 'vue-router'
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import router from './router/config-router'
// console.log('Hello world', vConsole)
// remove it when you finish debugging
// vConsole.destroy()
import Dialog from '@/components/Dialog'
import keepAlive from './store/modules/keepAlive'
export default {
  components: { Dialog },
  name: 'App',
  setup() {
    const store = useStore()
    const show = ref(false)
    // const portFail = ref(false)
    const timer = ref(null)
    const router = useRouter()
    const flag = ref(false)
    const cachedViews = computed(() => store.state.keepAlive?.cachedViews)
    const errorHandler = computed(() => store.state.keepAlive?.errorHandler)
    console.log(8080,errorHandler.value);
    const handleOK = () => {
      show.value = false
      localStorage.setItem('flag', 0)
      window.location.reload()
    }
    // const handlePortOK = () => {
    //   portFail.value = false
    //   localStorage.setItem('portFailFlag',3)
    // }
    onMounted(() => {
      // if(localStorage.getItem('portFailFlag') == 3){
      //   portFail.value = false
      // }
      // let newDate = (new Date()).getTime()
      // let oldDate = new Date('2023/03/17 17:00:00').getTime()
      // if((newDate < oldDate) && localStorage.getItem('portFailFlag')!= 3){
      //   portFail.value = true
      // }
      setTimeout(() => {
        if (localStorage.getItem('flag') === '1') {
          show.value = true
        }
      }, 700)
      window.addEventListener('click', (val) => {
        if (timer.value) {
          timer.value = null
        }
        timer.value = setTimeout(() => {
          if (localStorage.getItem('flag') === '1') {
            show.value = true
          }
        }, 700)
      })
    })
    watch(()=>errorHandler,(val)=>{
      console.log(666,val)
    })
    return {
      cachedViews,
      errorHandler,
      show,
      // portFail,
      handleOK,
      // handlePortOK,
      timer
    }
  }
}
</script>
<style lang="scss">
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
</style>
