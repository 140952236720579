const state = {
  cachedViews: [], // 示例 ['TemplateB', 'TemplateC']
}

const mutations = {
  // 添加缓存
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) {
      return
    }
    state.cachedViews.push(view.name)
  },
  // 移除缓存
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  }
}

export default {
  // 开启命名模块
  namespaced: true,
  state,
  mutations
}
