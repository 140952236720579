<template>
  <my-dialog :show="tipDialog" @onCancel="onCancel" :showLeftBtn="false" okText="我知道了" @onHandleOk="onHandleOk">
    <template #content style="text-align: center">暂无权限，请联系管理员</template>
  </my-dialog>
</template>

<script>
export default {
  name: 'perDialog',
  props: {
    tipDialog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const onCancel = () => {
      emit('update:tipDialog', false)
    }

    const onHandleOk = () => {
      emit('update:tipDialog', false)
    }

    return {
      onCancel,
      onHandleOk
    }
  },
  emits: ['update:tipDialog']
}
</script>
