import axios from '@/utils/request'


const apis = {
    getVersion: '/drinks/app/v1/version/getInfo', //获取版本信息
    updateVersion: '/drinks/app/v1/version/updateVersion',//修改版本信息
}

export function getVersion() {
    return axios({
      url: apis.getVersion,
      method: 'GET'
    })
}

export function updateVersion(versionInfo) {
    return axios({
      url: `${apis.updateVersion}?versionInfo=${versionInfo}`,
      method: 'GET'
    })
}

export default{
    getVersion
}
