<!--
 * @version: 1.0
 * @Author: 李卓扬
-->
<template>
  <van-nav-bar
    :title="title"
    fixed
    :class="showBg ? '' : 'bgColor'"
    :border="border"
    @click-left="clickLeft"
    @click-right="clickRight"
    :left-arrow="leftArrow"
    :right-text="rightText"
    :left-text="leftText"
    :placeholder="placeholder"
  >
    <template #left v-if="leftArrow">
      <slot name="left" v-if="slots.left"> </slot>
      <van-icon
        :name="beforRouter == currentRouter ? 'wap-home-o' : 'arrow-left'"
        v-else
        size="18"
        :color="showBg ? '#353535' : '#343434'"
      />
    </template>
    <template #title v-if="slots.title">
      <slot name="title"></slot>
    </template>
    <template #right v-if="slots.right">
      <slot name="right"> </slot>
    </template>
  </van-nav-bar>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'
import { useSlots, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'navBar',
  emits: ['onClickLeft', 'onClickRight'],
  props: {
    showBg: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '标题'
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    leftArrow: {
      type: Boolean,
      default: true
    },
    onClickLeft: {
      type: Function
    },
    onClickRight: {
      type: Function
    }
  },
  setup(props, context) {
    const { emit } = context
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const slots = useSlots()
    const beforRouter = JSON.parse(sessionStorage.getItem('beforRouter'))
    const currentRouter = window.location.href
    function clickLeft(item) {
      if (!props.leftArrow) {
        return
      }
      if (props.onClickLeft) {
        props.onClickLeft(item)
        return
      }
      if (beforRouter == currentRouter) {
        sessionStorage.setItem('beforRouter', JSON.stringify('home'))
        // store.commit('beforRouter/getBeforRouter', 'home')
        router.replace('/home')
      } else {
        router.back()
      }
    }
    function clickRight(item) {
      if (props.onClickRight) {
        props.onClickRight(item)
        return
      }
    }
    return {
      currentRouter,
      beforRouter,
      slots,
      clickRight,
      clickLeft
    }
  }
}
</script>
<style lang="scss" scoped>
@function rem($px) {
  @return $px / 37.5 * 1rem;
}
:deep .van-nav-bar__title {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 24px;
}
.bgColor {
  background: transparent;
  :deep .van-nav-bar__title {
    color: #343434;
  }
}
.van-nav-bar__placeholder {
  :deep(.van-nav-bar__title) {
    font-size: 17px;
    font-weight: 500;
    color: #343434;
    line-height: 24px;
  }
}
</style>
